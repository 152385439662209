import { AuthService } from 'app/services/auth/auth.service';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { NavbarComponent } from 'app/layouts/navbar/navbar.component';

// stateConfig.$inject = ['$stateProvider'];
//
//     export default function stateConfig($stateProvider: StateProvider) {
//         console.log('Configuring root state with: ' + $stateProvider);
//         $stateProvider.state('app', {
//             abstract: true,
//             views: {
//                 'navbar@': {
//                     component: 'navbar'
//                     // template: 'NAVBAR'
//                 }
//             },
//             resolve: {
//                 authorize: ['Auth',
//                     function (Auth) {
//                         return Auth.authorize();
//                     }
//                 ],
//                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                     $translatePartialLoader.addPart('global');
//                     $translatePartialLoader.addPart('country');
//                     return $translate.refresh();
//                 }]
//             }
//         });
//
//         $stateProvider.onInvalid((x) => {
//             console.error('invalid transition ' + x);
//         });
//     }

export const appState: Ng2StateDeclaration = {
  name: 'app',
  abstract: true,
  views: {
    'navbar@': {
      component: NavbarComponent,
      // template: 'NAVBAR'
    },
  },
  resolve: [
    {
      token: 'authorize',
      deps: [AuthService],
      resolveFn: (authService: AuthService) => authService.authorize(),
    },
    // {
    //   tokenName: 'translatePartialLoader',
    //   resolveFn: ($translate, $translatePartialLoader) => {
    //     $translatePartialLoader.addPart('global');
    //     $translatePartialLoader.addPart('country');
    //     return $translate.refresh();
    //   }
    // }
  ],
};
