export const EVENT_DETAIL_STATE = 'app.entity.event.detail';

export const ownEventsFutureState = {
  name: 'app.my-events.**',
  url: '/my-event',
};

// legacyComponents
//     .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
  console.log('Configuring event states');
  // $stateProvider
  // .state('app.entity.event', {
  //     // parent: 'entity',
  //     url: '/event',
  //     data: {
  //         pageTitle: 'kisanet.event.home.title'
  //     },
  //     views: {
  //         'content@': {
  //             component: 'ownEvents'
  //         }
  //     },
  //     resolve: {
  //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
  //             $translatePartialLoader.addPart('event');
  //             $translatePartialLoader.addPart('global');
  //             $translatePartialLoader.addPart('registration');
  //             $translatePartialLoader.addPart('sport');
  //             return $translate.refresh();
  //         }]
  //     }
  // })
  // .state(EVENT_DETAIL_STATE, {
  //     // parent: 'event',
  //     url: '/details/{id}',
  //     views: {
  //         'content@': {
  //             component: EventDetailParentComponent
  //         }
  //     },
  //     resolve: {
  //         entity: ['$stateParams', 'Event', 'Context', function($stateParams, Event, Context) {
  //             var event = Event.getPublic({id : $stateParams.id});
  //             Context.set('event', event);
  //             return event.$promise;
  //         }],
  //         event: ['entity', (entity) => entity],
  //         report: ['$stateParams', 'Event', function($stateParams, Event) {
  //             return Event.getReport({id: $stateParams.id, onlyDefaultRound: true}).$promise;
  //         }],
  //         previousState: ["$state", function ($state) {
  //             var currentStateData = {
  //                 name: $state.current.name || 'event',
  //                 params: $state.params,
  //                 url: $state.href($state.current.name, $state.params)
  //             };
  //             return currentStateData;
  //         }]
  //     },
  //     onEnter:['Context', 'entity', function (Context, event) {
  //         Context.set('event', event);
  //     }],
  //     onExit:['Context', function (Context) {
  //         Context.clear('event');
  //     }]
  // })
  // .state('app.entity.event.detail.edit', {
  //     // parent: 'event-detail',
  //     url: '/edit',
  //     views: {
  //         'content@': {
  //             component: 'eventDialog',
  //             bindings: { event: 'entity' }
  //         }
  //     }
  // })
  // .state('app.entity.event.detail.judge-panels', {
  //     // parent: 'event-detail',
  //     url: '/judge-panels',
  //     views: {
  //         'content@': {
  //             component: EventJudgePanelsComponent
  //         }
  //     },
  //     resolve: {
  //       startLists: ['$stateParams', 'Event', function($stateParams, Event) {
  //         return Event.getStartLists({id : $stateParams.id}).$promise;
  //       }],
  //       judgePanels: ['$stateParams', 'Event', function($stateParams, Event) {
  //         return Event.getJudgePanels({id : $stateParams.id}).$promise;
  //       }],
  //       judges: ['$stateParams', 'Event', function($stateParams, Event) {
  //         return Event.getJudges({id : $stateParams.id}).$promise;
  //       }]
  //     }
  //   })
  //     .state('event-protocols', {
  //         parent: 'event-detail',
  //         url: '/protocols?returnTo&returnToParams',
  //         views: {
  //             'content@': {
  //                 component: 'eventProtocols'
  //             }
  //         },
  //         resolve: {
  //             sportData: ['Sport', function(Sport) {
  //                 return Sport.loadData();
  //             }],
  //             translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
  //                 $translatePartialLoader.addPart('results');
  //                 return $translate.refresh();
  //             }],
  //         },
  //         onEnter:[function () {
  //             Tawk_API.hideWidget();
  //         }],
  //         onExit:[function () {
  //             Tawk_API.showWidget();
  //         }]
  //     })
  //     .state('event-protocol', {
  //         parent: 'event-protocols',
  //         url: '/:roundId',
  //         views: {
  //             'content@': {
  //                 component: 'eventProtocol'
  //             }
  //         },
  //         resolve: {
  //             round: ['$stateParams', 'entity', function($stateParams, event) {
  //                 var roundId = parseInt($stateParams.roundId);
  //                 return event.categories.reduce(function (acc, cat) {
  //                     return acc || cat.rounds.find(function (round) {
  //                         return round.id === roundId;
  //                     });
  //                 }, null);
  //             }],
  //             entries: ['$stateParams', 'Round', function($stateParams, Round) {
  //                 return Round.getStartListEntries({id : $stateParams.roundId}).$promise;
  //             }],
  //             judgePanels: ['$stateParams', 'Round', function ($stateParams, Round) {
  //                 return Round.getJudgePanels({id : $stateParams.roundId}).$promise;
  //             }],
  //             roundParticipants: ['$stateParams', 'Round', function ($stateParams, Round) {
  //                 return Round.getRoundParticipants({id : $stateParams.roundId}).$promise;
  //             }],
  //             judges: ['$stateParams', 'Event', function($stateParams, Event) {
  //               return Event.getJudges({id : $stateParams.id}).$promise;
  //             }]
  //         },
  //         onEnter:[function () {
  //             Tawk_API.hideWidget();
  //         }]
  // })
  // .state('app.entity.event.detail.event-protocols', {
  //     // parent: 'event-detail',
  //     url: '/protocols?returnTo&returnToParams',
  //     views: {
  //         'content@': {
  //             component: 'eventProtocols'
  //         }
  //     },
  //     resolve: {
  //         sportData: ['Sport', function(Sport) {
  //             return Sport.loadData();
  //         }]
  //     },
  //     onEnter:[function () {
  //         Tawk_API.hideWidget();
  //     }],
  //     onExit:[function () {
  //         Tawk_API.showWidget();
  //     }]
  // })
  // .state('app.entity.event.detail.event-protocols.event-protocol', {
  //     // parent: 'event-protocols',
  //     url: '/:roundId',
  //     views: {
  //         'content@': {
  //             component: 'eventProtocol'
  //         }
  //     },
  //     resolve: {
  //         round: ['$stateParams', 'entity', function($stateParams, event) {
  //             var roundId = parseInt($stateParams.roundId);
  //             return event.categories.reduce(function (acc, cat) {
  //                 return acc || cat.rounds.find(function (round) {
  //                     return round.id === roundId;
  //                 });
  //             }, null);
  //         }],
  //         entries: ['$stateParams', 'Round', function($stateParams, Round) {
  //             return Round.getStartListEntries({id : $stateParams.roundId}).$promise;
  //         }],
  //         judgePanels: ['$stateParams', 'Round', function ($stateParams, Round) {
  //             return Round.getJudgePanels({id : $stateParams.roundId}).$promise;
  //         }],
  //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
  //             $translatePartialLoader.addPart('results');
  //             return $translate.refresh();
  //         }],
  //         roundParticipants: ['$stateParams', 'Round', function ($stateParams, Round) {
  //             return Round.getRoundParticipants({id : $stateParams.roundId}).$promise;
  //         }],
  //         registrationPoints: ['$stateParams', 'Round', function ($stateParams, Round) {
  //             return Round.getRoundRegistrations({id : $stateParams.roundId}).$promise;
  //         }]
  //     }
  // })
  //     .state('app.entity.event.detail.event-protocols.multi-round-event-protocol', {
  //         url: '/multi-round/:categoryId',
  //         views: {
  //             'content@': {
  //                 component: 'multiRoundEventProtocol'
  //             }
  //         },
  //         resolve: {
  //             categoryId: ['$stateParams', function($stateParams) {
  //                 return parseInt($stateParams.categoryId, 10);
  //             }],
  //             participantResults: ['categoryId', 'Category', function(categoryId, Category) {
  //                 return Category.getResults({id: categoryId, forceShowPartialPoints: true}).$promise;
  //             }],
  //             registrations: ['categoryId', 'Registration', function(categoryId, Registration) {
  //                 return Registration.getForCategory({categoryId: categoryId}).$promise;
  //             }]
  //         },
  //         onEnter:[function () {
  //             Tawk_API.hideWidget();
  //         }],
  //         onExit:[function () {
  //             Tawk_API.showWidget();
  //         }]
  //     })
  //     .state('event-new', {
  //         parent: 'event',
  //         url: '/new',
  //         views: {
  //             'content@': {
  //                 templateUrl: 'app/entities/event/event-dialog.html',
  //                 controller: 'EventDialogController',
  //                 controllerAs: 'vm'
  //             }
  //         },
  //         resolve: {
  //             entity: [function() {
  //                 return {
  //                     startDate: null,
  //                     endDate: null,
  //                     registrationStartDate: null,
  //                     registrationEndDate1: null,
  //                     registrationEndDate2: null,
  //                     name: null,
  //                     city: null,
  //                     country: null,
  //                     description: null,
  //                     id: null,
  //                     categories: []
  //                 };
  //             }]
  //         }
  //     })
  //     .state('event-delete', {
  //         parent: 'event',
  //         url: '/{id}/delete',
  //         onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
  //             $uibModal.open({
  //                 templateUrl: 'app/entities/event/event-delete-dialog.html',
  //                 controller: 'EventDeleteController',
  //                 controllerAs: 'vm',
  //                 size: 'md',
  //                 resolve: {
  //                     entity: ['Event', function(Event) {
  //                         return Event.get({id : $stateParams.id}).$promise;
  //                     }]
  //                 }
  //             }).result.then(function() {
  //                 $state.go('event', null, { reload: 'event' });
  //             }, function() {
  //                 $state.go('^');
  //             });
  //         }]
  //     });
}
