<section class="container content-section auth-page">
  <header class="content-section__header">
    <h1 class="content-section__heading auth-page__heading" [translate]="'reset.request.title'">
      Reset your password
    </h1>
  </header>

  <main class="content-section__main auth-page__main">
    <div class="alert alert-danger" [translate]="'reset.request.messages.notfound'" *ngIf="errorEmailNotExists">
      <strong>E-Mail address isn't registered!</strong> Please check and try again.
    </div>

    <div class="alert alert-warning" *ngIf="!success">
      <span [translate]="'reset.request.messages.info'">
        Enter the e-mail address you used to register.
      </span>
    </div>

    <div class="alert alert-success" *ngIf="success == 'OK'">
      <span>
        <div [innerHtml]="'reset.request.messages.success' | translate:successMessageParams">
          Email has been sent
        </div>
      </span>
    </div>

    <form
      *ngIf="!success"
      name="form"
      #form="ngForm"
      role="form"
      novalidate
      (ngSubmit)="requestReset()"
      show-validation
    >
      <div class="form-group">
        <label for="username" [translate]="'global.form.username'">E-mail</label>
        <input
          type="text"
          class="form-control"
          id="username"
          name="username"
          placeholder="{{'global.form.username.placeholder' | translate}}"
          [(ngModel)]="resetAccount.email"
          [minlength]="3"
          [maxlength]="100"
          required
        />

        <!--                <div *ngIf="form.email.$dirty && form.email.$invalid">-->
        <!--                    <div-->
        <!--                        class="invalid-feedback"-->
        <!--                        *ngIf="form.email.$error.required"-->
        <!--                        [translate]="'global.messages.validate.username.required'"-->
        <!--                    >-->
        <!--                        Your e-mail is required.-->
        <!--                    </div>-->

        <!--                    <div-->
        <!--                        class="invalid-feedback"-->
        <!--                        *ngIf="form.email.$error.email"-->
        <!--                        [translate]="'global.messages.validate.email.invalid"-->
        <!--                    >-->
        <!--                        Your e-mail is invalid.-->
        <!--                    </div>-->

        <!--                    <div-->
        <!--                        class="invalid-feedback"-->
        <!--                        *ngIf="form.email.$error.minlength"-->
        <!--                        [translate]="'global.messages.validate.username.minlength'"-->
        <!--                    >-->
        <!--                        Your e-mail is required to be at least 5 characters.-->
        <!--                    </div>-->

        <!--                    <div-->
        <!--                        class="invalid-feedback"-->
        <!--                        *ngIf="form.email.$error.maxlength"-->
        <!--                        [translate]="'global.messages.validate.username.maxlength'"-->
        <!--                    >-->
        <!--                        Your e-mail cannot be longer than 100 characters.-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>

      <div class="auth-page__buttons">
        <button
          type="submit"
          [disabled]="!form.valid"
          class="btn btn-blue btn-block"
          [translate]="'reset.request.form.button'"
        >
          Reset
        </button>
      </div>
    </form>
  </main>
</section>
