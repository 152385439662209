import { ResetRequestComponent } from 'app/account/reset/request/reset.request.component';
import { Ng2StateDeclaration } from '@uirouter/angular';

export const resetRequestState: Ng2StateDeclaration = {
  name: 'app.account.requestReset',
  url: '/reset/request',
  data: {
    authorities: [],
    pageTitle: 'event.home.title',
  },
  views: {
    'content@': {
      component: ResetRequestComponent,
    },
  },
  onEnter: () => console.log('Entering resetRequestState'),
};
